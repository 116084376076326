<template>
  <div class="main">
    <Head />
    <div class="help-content">
      <div class="help-box">
        <ul>
          <li>
            <div class="bottom problem">
              <div class="p_content">
                <p class="p_title title" style="text-align: center">
                  第三方SDK目录
                </p>
                <p class="p_icon">华为渠道SDK</p>
                <p class="p_icon">使用目的：接入账号系统和使用支付功能</p>
                <p class="p_icon">
                  官网链接：<a
                    href="https://developer.huawei.com/consumer/cn/doc/development/AppGallery-connect-Library/agc-auth-android-sdkdownload-0000001058498518"
                  >https://developer.huawei.com/consumer/cn/doc/development/AppGallery-connect-Library/agc-auth-android-sdkdownload-0000001058498518</a>
                </p>
                <p class="p_icon"><strong>&nbsp;</strong></p>
                <p class="p_icon">vivo渠道SDK</p>
                <p class="p_icon">使用目的：接入账号系统和使用支付功能</p>
                <p class="p_icon">
                  官网链接：<a
                    href="https://dev.vivo.com.cn/documentCenter/doc/6"
                  >https://dev.vivo.com.cn/documentCenter/doc/6</a>
                </p>
                <p class="p_icon"><strong>&nbsp;</strong></p>
                <p class="p_icon">百度渠道SDK</p>
                <p class="p_icon">使用目的：接入账号系统和使用支付功能</p>
                <p class="p_icon">
                  官网链接：<a
                    href="https://g.baidu.com/gpbusiness/#/index"
                  >https://g.baidu.com/gpbusiness/#/index</a>
                </p>

                <p class="p_icon"><strong>&nbsp;</strong></p>
                <p class="p_icon">应用宝渠道SDK</p>
                <p class="p_icon">
                  使用目的：接入账号系统和使用支付功能、使用分享功能
                </p>
                <p class="p_icon">
                  官网链接：<a
                    href="https://wiki.open.qq.com/index.php?title=YSDK%E4%B8%8B%E8%BD%BD"
                  >https://wiki.open.qq.com/index.php?title=YSDK%E4%B8%8B%E8%BD%BD</a>
                </p>
                <p class="p_icon"><strong>&nbsp;</strong></p>
                <p class="p_icon">小米渠道SDK</p>
                <p class="p_icon">使用目的：接入账号系统和使用支付功能</p>
                <p class="p_icon">
                  官网链接：<a
                    href="https://dev.mi.com/console/doc/detail?pId=102"
                  >https://dev.mi.com/console/doc/detail?pId=102</a>
                </p>

                <p class="p_icon">&nbsp;</p>
                <p class="p_icon">魅族渠道SDK</p>
                <p class="p_icon">使用目的：接入账号系统和使用支付功能</p>
                <p class="p_icon">
                  官网链接：<a
                    href="http://open-wiki.flyme.cn/doc-wiki/index?title=SDK%E6%8E%A5%E5%85%A5%E6%89%8B%E5%86%8C"
                  >http://open-wiki.flyme.cn/doc-wiki/index?title=SDK%E6%8E%A5%E5%85%A5%E6%89%8B%E5%86%8C</a>
                </p>

                <p class="p_icon">&nbsp;</p>
                <p class="p_icon">OPPO渠道SDK</p>
                <p class="p_icon">使用目的：接入账号系统和使用支付功能</p>
                <p class="p_icon">
                  官网链接：<a
                    href="https://open.oppomobile.com/wiki/doc#id=10470"
                  >https://open.oppomobile.com/wiki/doc#id=10470</a>
                </p>
                <p class="p_icon">&nbsp;</p>
                <p class="p_icon">联想渠道SDK</p>
                <p class="p_icon">使用目的：接入账号系统和使用支付功能</p>
                <p class="p_icon">
                  官网链接：<a
                    href="http://open.lenovomm.com/"
                  >http://open.lenovomm.com/</a>
                </p>
                <p class="p_icon">&nbsp;</p>
                <p class="p_icon">九游渠道SDK</p>
                <p class="p_icon">使用目的：接入账号系统和使用支付功能</p>
                <p class="p_icon">
                  官网链接：<a
                    href="https://aligames.open.uc.cn/document/doc/detail/15"
                  >https://aligames.open.uc.cn/document/doc/detail/15</a>
                </p>
                <p class="p_icon"><strong>&nbsp;</strong></p>
                <p class="p_icon">手Q渠道SDK</p>
                <p class="p_icon">使用目的：接入账号系统和使用支付功能</p>
                <p class="p_icon">
                  官网链接：<a
                    href="https://game.qq.com/contract.shtml"
                  >https://game.qq.com/contract.shtml</a>
                </p>
                <p class="p_icon">&nbsp;</p>
                <p class="p_icon">微信渠道SDK</p>
                <p class="p_icon">
                  使用目的：接入账号系统和使用支付功能，分享功能
                </p>
                <p class="p_icon">
                  官网链接：<a
                    href="https://pay.weixin.qq.com/wiki/doc/apiv3/wxpay/pages/index.shtml"
                  >https://pay.weixin.qq.com/wiki/doc/apiv3/wxpay/pages/index.shtml</a>
                </p>
                <p class="p_icon">&nbsp;</p>
                <p class="p_icon">支付宝支付SDK</p>
                <p class="p_icon">使用目的：使用支付功能</p>
                <p class="p_icon">
                  官网链接：<a
                    href="https://opendocs.alipay.com/open/204"
                  >https://opendocs.alipay.com/open/204</a>
                </p>
                <p class="p_icon">&nbsp;</p>
                <p class="p_icon">支付宝h5支付SDK</p>
                <p class="p_icon">使用目的：使用支付功能</p>
                <p class="p_icon">
                  官网链接：<a
                    href="http://myjsapi.alipay.com/jsapi/"
                  >http://myjsapi.alipay.com/jsapi/</a>
                </p>
                <p class="p_icon">&nbsp;</p>
                <p class="p_icon">QQ分享SDK</p>
                <p class="p_icon">使用目的：使用分享功能</p>
                <p class="p_icon">
                  官网链接：<a
                    href="https://wiki.connect.qq.com/sdk%e4%b8%8b%e8%bd%bd"
                  >https://wiki.connect.qq.com/sdk%e4%b8%8b%e8%bd%bd</a>
                </p>

                <p class="p_icon">&nbsp;</p>
                <p class="p_icon">神州付SDK</p>
                <p class="p_icon">使用目的：使用支付功能</p>
                <p class="p_icon">
                  官网链接：<a
                    href="https://cardmaster.shenzhoufu.com/"
                  >https://cardmaster.shenzhoufu.com/</a>
                </p>
                <p class="p_icon">&nbsp;</p>
                <p class="p_icon">udesk客服SDK</p>
                <p class="p_icon">使用目的：收集玩家问题</p>
                <p class="p_icon">
                  官网链接：<a
                    href="https://www.udesk.cn/apply-o2o.html"
                  >https://www.udesk.cn/apply-o2o.html</a>
                </p>
                <p class="p_icon">&nbsp;</p>
                <p class="p_icon">极验一键登录SDK</p>
                <p class="p_icon">使用目的：方便玩家登录</p>
                <p class="p_icon">
                  官网链接：<a
                    href="https://www.geetest.com/"
                  >https://www.geetest.com/</a>
                </p>
                <p class="p_icon">&nbsp;</p>
                <p class="p_icon">Bugly异常信息收集SDK</p>
                <p class="p_icon">使用目的：收集APP崩溃信息</p>
                <p class="p_icon">
                  官网链接：<a
                    href="https://bugly.qq.com/v2/downloads"
                  >https://bugly.qq.com/v2/downloads</a>
                </p>
                <p class="p_icon">&nbsp;</p>
                <p class="p_icon">银联支付SDK</p>
                <p class="p_icon">使用目的：使用支付功能</p>
                <p class="p_icon">
                  官网链接：<a
                    href="https://cn.unionpay.com/upowhtml/cn/templates/index/index.html"
                  >https://cn.unionpay.com/upowhtml/cn/templates/index/index.html</a>
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '../modules/Head.vue'
import Foot from '../modules/Foot.vue'
export default {
  components: { Head, Foot }
}
</script>

<style scoped>
* {
  word-break: break-all;
  line-height: 1.5rem;
}

a {
  text-decoration: none;
  color: blue;
}
.help-content {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
   padding-top:3.575rem;
}
.help-content .help-box {
  margin: 1.25rem 0;
  overflow: hidden;
  width: 35.625rem;
  background-color: #fff;
  padding: 0.8125rem;
  box-sizing: border-box;
  border-radius: 0.1875rem;
  border: 0.0625rem solid #eee;
}
.help-content .help-box li {
  background-color: #f5f5f5;
  border-radius: 0.1875rem;
  font-size: 0.5625rem;
  margin-bottom: 0.625rem;
}

.help-content .help-box li:last-of-type {
  margin-bottom: 0;
}

.help-content .help-box li .problem {
  padding: 0 1.25rem 0.625rem 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.help-content .help-box li .problem .content {
  padding: 0.625rem;
  margin-top: 0.625rem;
  background-color: #f5f5f5;
  border-radius: 0.1875rem;
  border: 0.0325rem solid #e7e7e7;
  font-size: 0.4375rem;
  display: flex;
  flex-direction: column;
  vertical-align: top;
}

.help-content .help-box li .bottom {
  border-top: 0.0325rem solid #eee;
  display: block;
}

.problem p {
  cursor: pointer;
}

.p_content .p_title {
  font-size: 0.5587rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.p_content .p_icon {
  text-indent: 2em;
  line-height: 1.2rem;
  font-size: 0.4375rem;
}

</style>
